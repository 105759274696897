<template>
  <v-card elevation="0" class="pt-2">
    <v-form lazy-validation ref="form">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="controllerID"
              :data-layout="KEYBOARD.KEYSETS.NORMAL"
              @focus="showTouchKeyboard"
              :label="$t('generic.lang_controllerID')"
              autocomplete="off"
              outlined
              required
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="controllerShortcode"
              :data-layout="KEYBOARD.KEYSETS.NORMAL"
              @focus="showTouchKeyboard"
              :label="$t('generic.lang_controller_shortcode')"
              autocomplete="off"
              outlined
              required
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn
          :disabled="this.loading"
          :loading="this.loading"
          @click="addData"
          color="success"
          >{{ $t("generic.lang_edit") }}
        </v-btn>
      </v-card-actions>
    </v-form>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidthExpanded"
        id="onScreenKeyboard"
        v-if="touchKeyboard.visible"
      />
    </div>
  </v-card>
</template>

<script>
import { Events } from "@/plugins/events";
//configs
import mixin from "@/mixins/KeyboardMixIns";
import { ENDPOINTS } from "@/config";

export default {
  name: "ReplicationSettingsComponent",
  mixins: [mixin],
  data: () => {
    return {
      ENDPOINTS,
      loading: false,
      controllerID: "",
      controllerShortcode: "",
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    goBacktoItem: function () {
      this.$router.push("/erp/baseData/items");
    },
    getData() {
      this.loading = true;

      this.axios
        .get(ENDPOINTS.POS_REPLICATION.SETTINGS.GET)
        .then((res) => {
          if (res.data.STATUS === "SUCCESS") {
            this.controllerID = res.data.data.replication_controllerID;
            this.controllerShortcode =
              res.data.data.replication_controllerShortcode;
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addData() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;

      this.axios
        .post(ENDPOINTS.POS_REPLICATION.SETTINGS.UPDATE, {
          controllerID: this.controllerID,
          controllerShortcode: this.controllerShortcode,
        })
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            Events.$emit("showSnackbar", {
              color: "success",
              message: this.$t("generic.lang_success"),
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
