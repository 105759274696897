<template>
  <div>
    <page-title
      :heading="$t('settings.lang_settings')"
      :subheading="$t('settings.lang_settings')"
      :icon="icon"
    ></page-title>
    <div class="app-main__inner">
      <replication-settings-component />
    </div>
  </div>
</template>

<script>
import PageTitle from "../../Layout/Components/PageTitle.vue";
import ReplicationSettingsComponent from "../../components/posReplication/ReplicationSettingsComponent.vue";

export default {
  name: "ReplicationSettingsView",
  components: {
    PageTitle,
    ReplicationSettingsComponent,
  },

  data: () => ({
    icon: "pe-7s-config icon-gradient bg-tempting-azure",
  }),
};
</script>
